import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from 'src/app/models/auth';
import { Md5 } from 'ts-md5';

@Component({
    selector: 'app-auth-modal',
    templateUrl: './auth-modal.component.html',
    styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit {
    private authHash: string | Int32Array;
    public login: Auth;
    public tryAgain = false;
    constructor(
        private readonly activeModal: NgbActiveModal,
        private readonly fireStore: AngularFirestore) { }

    ngOnInit(): void {
        this.fireStore.collection<Auth>('auth').valueChanges().subscribe(dataSet => {
            this.authHash = Md5.hashStr(dataSet[0].authentification);
        });
        this.login = {authentification: ''};
    }

    public close(): void {
        if (Md5.hashStr(this.login.authentification) === this.authHash) {
            this.activeModal.close(true);
        } else {
            this.tryAgain = true;
        }
    }
}
