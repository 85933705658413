<div class="container bg-light align-bottom py-3 h-5">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <a routerLink="/home">Home</a><br>
            <a routerLink="/watchparty">WatchParty</a>
        </div>
        <div class="align-content-bottom">
            &copy;  {{year}} SchmidtCloud
        </div>
        <div>
            <a routerLink="/impressum">Impressum</a><br>
            <a routerLink="/impressum/contact">Kontakt</a>
        </div>
    </div>
</div>
