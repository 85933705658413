import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { WatchpartyComponent } from './watchparty/watchparty/watchparty.component';
import { InvitationComponent } from './watchparty/invitation/invitation.component';
import { LiveComponent } from './watchparty/live/live.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { CookieService } from 'ngx-cookie-service';
import { CookieModalComponent } from './modals/cookie-modal/cookie-modal.component';
import { ContactComponent } from './impressum/contact/contact.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { AuthModalComponent } from './modals/auth-modal/auth-modal.component';
import { ShopComponent } from './watchparty/shop/shop.component';
import { AnnaComponent } from './anna/anna.component';
import { Geschenk1Component } from './anna/geschenk1/geschenk1.component';
import { Geschenk2Component } from './anna/geschenk2/geschenk2.component';
import { HannahComponent } from './hannah/hannah.component';
import { GutscheinComponent } from './hannah/gutschein/gutschein.component';
import { PartiesComponent } from './parties/parties.component';
import { CocktailabendComponent } from './parties/cocktailabend/cocktailabend.component';
import { Geschenk3Component } from './anna/geschenk3/geschenk3.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    WatchpartyComponent,
    InvitationComponent,
    LiveComponent,
    ImpressumComponent,
    CookieModalComponent,
    ContactComponent,
    AuthModalComponent,
    ShopComponent,
    AnnaComponent,
    Geschenk1Component,
    Geschenk2Component,
    HannahComponent,
    GutscheinComponent,
    PartiesComponent,
    CocktailabendComponent,
    Geschenk3Component
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    FormsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent],
  entryComponents: [CookieModalComponent, AuthModalComponent]
})
export class AppModule {}
