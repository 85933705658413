import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Order, ShopItems, ShoppingCart } from 'src/app/models/shop-items';
import { InvitationService } from 'src/app/services/invitation.service';
import { ShopService } from 'src/app/services/shop.service';

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
    public liveEvent: boolean;
    public view: number;
    public shopItems: ShopItems[];
    public shoppingCart: ShoppingCart;
    public cartSize: number;
    private success = new Subject<string>();
    public successMessage = '';
    public alertType = 'Fehler:';
    public contactValidated = false;
    private orders: Order[];
    private maxDate: number;
    constructor(
        private readonly shopService: ShopService,
        private readonly invitiationService: InvitationService
    ) { }

    ngOnInit(): void {
        this.view = 0;
        this.invitiationService.liveEvent.subscribe(isLive => this.liveEvent = isLive);
        this.invitiationService.currentParties.subscribe(parties => {
            this.maxDate = Math.max(...parties.map(party => party.date.seconds * 1000));
            if (Date.now() >= this.maxDate){
                this.liveEvent = true;
            }
        });
        this.shoppingCart = {
            name: '',
            email: '',
            notice: '',
            time: Date.now(),
            products: [{
                product: '',
                quantity: 0
            }]
        };
        this.success.subscribe(message => this.successMessage = message);
        this.success.pipe(debounceTime(5000)).subscribe(() => {
            this.success.next('');
        });
        this.cartSize = 0;
        this.shoppingCart.products.pop();
        this.shopService.currentItems.subscribe(items => {
            this.shopItems = items;
        });
        this.shopService.currentOrders.subscribe(orders => {
            this.orders = orders;
        });
    }

    public switchViews(view: number): void {
        this.view = view;
    }

    public validateContact(): void {
        if (this.shoppingCart.email !== '' && this.shoppingCart.name !== '' && this.shoppingCart.email.includes('@')) {
            if (this.orders.find(order => order.email === this.shoppingCart.email) === undefined) {
                this.switchViews(2);
                this.contactValidated = true;
            } else {
                this.alertType = 'Fehler:';
                this.success.next('Du hast bereits Pizza bestellt! Wenn du etwas ändern willst, dann bitte eine kurze Mail an paul_schmidt@mail.de :)');
            }
        } else {
            this.alertType = 'Fehler:';
            this.success.next('Kontaktdaten nicht vollständig!');
        }
    }

    public getMaxItem(): number {
        if (this.cartSize !== 3) {
            return this.cartSize + 1;
        } else {
            return 3;
        }
    }

    public getQuantity(item: ShopItems): number {
        return this.shoppingCart.products
            .find(product => product.product === item.product) ?
            this.shoppingCart.products
                .find(product => product.product === item.product).quantity : 0;
    }

    public updateCart(event: any): void {
        const id: string = event.target.id;
        const qty: number = +event.target.value;
        const idx: number = this.shoppingCart.products.findIndex(product => product.product === id);
        if (this.cartSize < 3) {
            if (idx !== -1) {
                this.shoppingCart.products[idx].quantity += qty - this.shoppingCart.products[idx].quantity;
            } else {
                this.shoppingCart.products.push({
                    product: id,
                    quantity: qty,
                });
            }
            this.cartSize = this.shoppingCart.products.reduce((a, b) => (a + b.quantity), 0);
        } else {
            this.cartSize = 0;
            this.alertType = 'Fehler: ';
            this.successMessage = 'Du kannst leider nicht mehr als 3 Pizzen bestellen.';
        }
    }

    public placeOrder(): void {
        // only accept orders 1 1/2 to 1/2 hours before launch!
        const maxTime = Math.max(...this.orders.map(order => order.time));
        if (maxTime >= (this.maxDate / 1000 - 1800)) {
            this.alertType = 'Fehler:';
            this.success.next('Leider können keine Bestellungen mehr angenommen werden.');
            this.liveEvent = true;
        } else {
            if (maxTime === -Infinity) {
                this.shoppingCart.time = (this.maxDate / 1000 - 5400);
            } else {
                this.shoppingCart.time = maxTime + 600;
            }
            this.shopService.addOrder(this.shoppingCart).then(() => {
                this.alertType = 'Danke!';
                this.success.next('Vielen Dank für deine Bestellung!');
            });
            this.switchViews(3);
        }
    }
}
