import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { User, UserData } from '../models/user';
import { WatchParty } from '../models/watch-party';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {
    private readonly partySource = new BehaviorSubject([] as WatchParty[]);
    private readonly userSource = new BehaviorSubject([] as UserData[]);
    private readonly eventSource = new BehaviorSubject<boolean>(false);
    public currentUsers = this.userSource.asObservable();
    public currentParties = this.partySource.asObservable();
    public liveEvent = this.eventSource.asObservable();
    constructor(
        private readonly cookieService: CookieService,
        private readonly fireStore: AngularFirestore) {
        this.getUsers();
        this.getParties();
    }

    public updateLiveEvent(isLive: boolean): void {
        this.eventSource.next(isLive);
    }

    private updateParties(parties: WatchParty[]): void {
        this.partySource.next(parties);
    }

    private updateUsers(userData: UserData[]): void {
        this.userSource.next(userData);
    }

    public checkRegister(cookie: string): boolean {
        if (this.cookieService.check(cookie)) {
            return true;
        } else {
            return false;
        }
    }

    public getParties(): void {
        this.fireStore.collection<WatchParty>('watchparties').valueChanges().subscribe(parties => {
            this.updateParties(parties);
        });
    }

    public getUsers(): void {
        this.fireStore.collection<UserData>('Users').valueChanges().subscribe(users => {
            this.updateUsers(users);
        });
    }

    private async updateDoc(docPath: string, userData: UserData): Promise<void> {
        const doc = await this.fireStore.doc(docPath).get().toPromise();
        const events = doc.get('events') as string[];
        events.forEach(event => {
            if (userData.events.includes(event)) {
                console.log('Event already there!');
            } else {
                userData.events.push(event);
            }
        });
        this.fireStore.doc(docPath).update(userData);
    }

    private addDoc(docPath: string, userData: UserData): void {
        this.fireStore.doc(docPath).set(userData);
    }

    public registerMe(userData: UserData): void {
        const docPath = 'Users/' + userData.email;
        this.fireStore.doc(docPath).get().toPromise().then(doc => {
            if (doc.exists) {
                console.log('UserSet has to be updated!');
                this.updateDoc(docPath, userData);
            } else {
                console.log('New UserSet needed');
                this.addDoc(docPath, userData);
            }
        });
    }
}
