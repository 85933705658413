import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CookieModalComponent } from './modals/cookie-modal/cookie-modal.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'website';
    constructor(private readonly cookieService: CookieService,
                private readonly modalProvider: NgbModal) { }

    ngOnInit(): void {
        if (this.getCookie('cookies') === 'accepted') {
        } else {
            this.modalProvider.open(CookieModalComponent).result.then((result) => {
                if (this.cookieService.check('cookies')){
                    this.cookieService.delete('cookies');
                }
                this.cookieService.set('cookies', result, 30);
            }, (reason) => {
                console.log(reason);
            });
        }
    }
    getCookie(key: string): string {
        return this.cookieService.get(key);
    }
}
