<div class="container shadow-sm fill mt-2">
  <h2>Alles Liebe zu deinem Geburtstag!</h2>
  <div class="d-lg-flex mt-2">
    <div class="flex-fill">
      Wünschen dir:
    </div>
    <img src="../../../assets/hannah/anna_logo.png" class="img-style m-2">
    <img src="../../../assets/hannah/cora_logo.png" class="img-style m-2">
    <img src="../../../assets/hannah/nico_logo.png" class="img-style m-2">
    <img src="../../../assets/hannah/paul_logo.png" class="img-style m-2">
  </div>
  <hr>
  <div class="d-lg-flex flex-row justify-content-between align-items-center bg-hannah">
    <div class="w-auto h-auto flex-fill">
      <img src="../../../assets/hannah/option3.jpg" class="img-fluid">
    </div>
    <div class="d-flex flex-column m-5 w-75 h-100">
      <div>
        <h2 class="text-white">GUTSCHEIN<br> GEMEINSAME RUNDE</h2>
      </div>
      <div>
        <p class="text-white">
          Geselliger Abend in unserer Runde mit entspannter Abendgestaltung und gemeinsamen Kochen oder Grillen. Bei der
          Wahl der Gerichte und Abendgestaltung möchten wir dich einbinden.
        </p>
      </div>
    </div>
  </div>
  <hr>
  <div>
    Natürlich unter aktuell geltenden Corona-Auflagen und deinen Wünschen entsprechend ausgelegt. Terminierung erfolgt
    nach deinem Kalender.
  </div>
</div>
