<div class="container shadow-sm">
    <div class="d-flex justify-content-center flex-column" *ngIf="liveEvent !== undefined; else loadBlock">
        <h2>Now Live <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </h2>
        <div>
            <div class="picsum-img-wrapper">
                <div class="d-flex justify-content-center">
                    <img [src]="liveEvent.src" style="width: auto; height: 500px; max-height: 500px;"
                        [alt]="liveEvent.title">
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex justify-content-center">
            <strong>{{liveEvent.date * 1000 | date:'HH:mm'}} Uhr</strong>
        </div>
        <div class=" mt-2 d-flex justify-content-center">
            <a [href]="liveEvent.url" target="_blank" *ngIf="isRegistered; else registerBlock">
                <button type="button" class="btn btn-warning">Join Watchparty*</button>
            </a>
            <ng-template #registerBlock>
                <a routerLink="/watchparty/invitation">
                    <button type="button" class="btn btn-warning">Register me!</button>
                </a>
            </ng-template>
        </div>
        <div class="d-flex justify-content-center m-2 p-3">
            <div *ngIf="isRegistered">
                *This is an Amazon WatchParty link!
            </div>
        </div>
    </div>
    <ng-template #loadBlock>
        <div class="spinner text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </ng-template>
</div>