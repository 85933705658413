import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geschenk3',
  templateUrl: './geschenk3.component.html',
  styleUrls: ['./geschenk3.component.scss']
})
export class Geschenk3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
