<div class="container shadow-sm fill">
    <div class="p-5">
        <div class="card shadow-sm">
            <div class="card-header bg-primary text-light">
                <h3>Kontakt</h3>
            </div>
            <div class="card-text">
                Paul Schmidt<br>
                <a href="mailto:cloud.schmidt@mail.de">cloud.schmidt@mail.de</a><br>
                
            </div>
        </div>
    </div>
        
    </div>