import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AuthModalComponent } from 'src/app/modals/auth-modal/auth-modal.component';
import { WatchParty } from 'src/app/models/watch-party';
import { InvitationService } from 'src/app/services/invitation.service';

@Component({
    selector: 'app-watchparty',
    templateUrl: './watchparty.component.html',
    styleUrls: ['./watchparty.component.scss']
})
export class WatchpartyComponent implements OnInit {
    public watchParties: WatchParty[];
    public liveEvent: boolean;
    constructor(
        private readonly invitationService: InvitationService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly modalProvider: NgbModal,
        private readonly cookieService: CookieService) { }

    ngOnInit(): void {
        this.invitationService.liveEvent.subscribe(isLive => this.liveEvent = isLive);
        this.invitationService.currentParties.subscribe(parties => {
            if (parties.some(party => (
                (party.date.seconds * 1000 - Date.now() <= 3600000) &&
                (party.date.seconds * 1000 - Date.now() > 0)))){
                this.router.navigate(['live'], {relativeTo: this.route});
                this.invitationService.updateLiveEvent(true);
            } else {
                this.router.navigate(['invitation'], {relativeTo: this.route});
                this.invitationService.updateLiveEvent(false);
            }
        });
        if (this.getCookie('auth') === 'authenticated') {
        } else {
            this.modalProvider.open(
                AuthModalComponent,
                {backdrop: 'static', keyboard: false, backdropClass: 'modal-opacity'})
                .result
                .then((result) => {
                this.cookieService.set('auth', 'authenticated');
            }, (error) => {
                console.log(error);
            });
        }
    }

    getCookie(key: string): string {
        return this.cookieService.get(key);
    }
}
