import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cookie-modal',
    templateUrl: './cookie-modal.component.html',
    styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements OnInit {
    private reason: string;
    constructor(private readonly activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        this.reason = 'accepted';
    }

    public dismiss(): void {
        this.activeModal.dismiss();
    }
    public close(): void {
        this.activeModal.close(this.reason);
    }
}
