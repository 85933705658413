import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { User, UserData } from 'src/app/models/user';
import { FullParty, WatchParty } from 'src/app/models/watch-party';
import { InvitationService } from 'src/app/services/invitation.service';
import { debounceTime } from 'rxjs/operators';
import { tokenReference } from '@angular/compiler';

@Component({
    selector: 'app-invitation',
    templateUrl: './invitation.component.html',
    styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
    public fullParties: FullParty[];
    public noParties = false;
    public user: User;
    public registeredMovies: UserData[];
    private success = new Subject<string>();
    public successMessage = '';
    public alertType = 'Fehler:';
    private userData: UserData[];
    constructor(
        private readonly invitationService: InvitationService,
        private readonly fireStorage: AngularFireStorage,
        private readonly cookieService: CookieService
    ) { }
    ngOnInit(): void {
        this.invitationService.getUsers();
        this.user = {
            name: '',
            movie: '',
            email: ''
        };
        this.fullParties = [];
        this.success.subscribe(message => this.successMessage = message);
        this.success.pipe(debounceTime(5000)).subscribe(() => {
            this.success.next('');
        });
        this.invitationService.currentUsers.subscribe(users => {
            this.userData = users;
        });
        this.invitationService.currentParties.subscribe(parties => {
            parties.forEach((party, index) => {
                if (party.date.seconds * 1000 > Date.now()) {
                    this.getPictureURL(party).then(part => {
                        this.fullParties.push(part);
                    });
                    this.user.movie = party.title;
                }
            });
            setTimeout(() => {
                if (this.fullParties.length === 0) {
                    this.noParties = true;
                } else {
                    this.noParties = false;
                }
            }, 1000);
        });
    }

    public getRegisteredMovies(): UserData[] {
        const registeredMovies: UserData[] = [];
        const registrations = this.cookieService.get('watchparties').split(';');
        const registered = this.userData.filter(user => (registrations.includes(user.email)));
        const titles = this.fullParties.map(element => element.title);
        registered.forEach(registration => {
            registeredMovies.push({
                events: registration.events.filter(event => titles.includes(event)),
                name: registration.name,
                email: registration.email
            });
        });
        return registeredMovies;
    }

    public async getPictureURL(watchparty: WatchParty): Promise<FullParty> {
        try {
            const reference = this.fireStorage.ref(watchparty.picture);
            const party = watchparty as FullParty;
            party.src = await reference.getDownloadURL().toPromise();
            return party;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    public register(): void {
        const registration: UserData = {
            name: this.user.name,
            events: [this.user.movie],
            email: this.user.email
        };
        if (this.user.email.includes('@')) {
            let watchParties = this.cookieService.get('watchparties').split(';');
            if (watchParties.includes(this.user.email)) {
            } else {
                if (watchParties[0] === '') {
                    watchParties = [this.user.email];
                } else {
                    watchParties.push(this.user.email);
                }
                this.cookieService.delete('watchparties');
                this.cookieService.set('watchparties', watchParties.join(';'), 14);
            }
            this.invitationService.registerMe(registration);
        } else {
            this.alertType = 'Fehler:';
            this.success.next(' Email-Adresse nicht vollständig!');
        }
    }
}
