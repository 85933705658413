<div class="modal-header bg-primary">
    <h5 class="modal-title text-light">Login</h5>
</div>
<div class="modal-body">
    <p *ngIf="tryAgain" class="text-danger">
        Bitte erneut veruschen!
    </p>
    <form (keyup.enter)="close()">
        <div class="form-group">
            <label for="password">Passwort:</label>
            <input class="form-control" type="password" id="password" name="password" [(ngModel)]="login.authentification">
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">Login</button>
</div>