import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { CookieService } from 'ngx-cookie-service';
import { FullParty, WatchParty } from 'src/app/models/watch-party';
import { InvitationService } from 'src/app/services/invitation.service';

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {
    public liveEvent: FullParty;
    public isRegistered = false;
    constructor(
        private readonly fireStorage: AngularFireStorage,
        private readonly invitationService: InvitationService,
        private readonly cookieService: CookieService
    ) { }

    ngOnInit(): void {
        this.invitationService.currentParties.subscribe(parties => {
            const thisParty: WatchParty = parties.filter(party => (
                (party.date.seconds * 1000 - Date.now() <= 3600000) &&
                (party.date.seconds * 1000 - Date.now() > 0)))[0];
            this.liveEvent = thisParty as FullParty;
            this.getPictureURL(thisParty).then(party => this.liveEvent = party);
        });
        this.isRegistered = this.cookieService.check('watchparties');
    }

    public async getPictureURL(watchparty: WatchParty): Promise<FullParty> {
        if (watchparty !== undefined) {
            try {
                const reference = this.fireStorage.ref(watchparty.picture);
                const party = watchparty as FullParty;
                party.src = await reference.getDownloadURL().toPromise();
                return party;
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
    }
}
