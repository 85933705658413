<ngb-alert *ngIf="successMessage" type="warning" class="fixed-top">
  <strong>{{alertType}}</strong>{{successMessage}}
</ngb-alert>

<div class="container shadow-sm">
  <h2>Invitations</h2>
  <div *ngIf="fullParties.length === 0;else showItems">
    <div *ngIf="noParties === false; else noParty">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-template #noParty>
      <div class="d-flex justify-content-center align-items-center">
        <div class="card border-danger mb-3" style="max-width: 18rem;">
          <div class="card-body">
            <h5 class="card-title text-danger">Sorry...</h5>
            <p class="card-text text-danger">Keine Watchparty geplant! Bitte versuche es später noch einmal :)</p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #showItems>
    <ngb-carousel *ngIf="fullParties" class="bg-dark">
      <ng-template ngbSlide *ngFor="let party of fullParties">
        <div class="picsum-img-wrapper">
          <div class="d-flex justify-content-center">
            <img [src]="party.src" style="width: auto; height: 500px; max-height: 500px;" [alt]="party.title">
          </div>
        </div>
        <div class="carousel-caption">
          <div class="d-flex">
            <div>
              <strong>{{party.date.seconds * 1000 | date:'HH:mm'}} Uhr</strong><br>
              {{party.date.seconds * 1000 | date:'dd.MM.yyyy'}}
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
    <hr>
    <div>
      <form>
        <div class="form-row">
          <div class="col-12">
            <label for="selectMovie">WatchParty</label>
            <select [(ngModel)]="user.movie" name="selectMovie" class="form-control" id="selectMovie">
              <option *ngFor="let party of fullParties" [value]="party.title">{{party.title}} -
                {{party.date.seconds * 1000 | date:'HH:mm'}} Uhr</option>
            </select>
          </div>
        </div>
        <div class="form-row justify-content-between mt-3 mx-1">
          <div class="w-auto">
            <label for="email">Email-Adresse</label>
            <input [(ngModel)]="user.email" name="email" type="email" class="form-control" id="email"
              placeholder="name@example.com" required>
          </div>
          <div class="w-auto">
            <label for="name">Name</label>
            <input [(ngModel)]="user.name" name="name" type="name" class="form-control" required>
          </div>
        </div>
        <div class="form-group row my-3 px-3">
          <button class="btn btn-primary btn-block" type="submit" (click)="register()">Anmelden</button>
        </div>
      </form>
    </div>
  </ng-template>
  <hr>
  <div *ngIf="getRegisteredMovies().length === 0; else displayMovies">
    <div class="d-flex justify-content-center align-items-center">
      Keine Einladungen angenommen!
    </div>
  </div>
  <ng-template #displayMovies>
    <div class="pb-2">
      <h3>My Invitations</h3>
      <table class="table table-bordered">
        <thead class="bg-primary text-white">
          <tr>
            <th>Email</th>
            <th>WatchParty</th>
          </tr>
        </thead>
        <tbody *ngFor="let registration of getRegisteredMovies()">
          <tr *ngFor="let event of registration.events;let isFirst = first">
            <td *ngIf="isFirst" [rowSpan]="registration.events.length">{{registration.email}}</td>
            <td>{{event}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>
