import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { Order, ShopItems, ShoppingCart } from '../models/shop-items';


@Injectable({
    providedIn: 'root'
})
export class ShopService {
    private readonly orderSource = new BehaviorSubject([] as Order[]);
    private readonly itemSource = new BehaviorSubject([] as ShopItems[]);
    public currentOrders = this.orderSource.asObservable();
    public currentItems = this.itemSource.asObservable();
    constructor(
        private readonly cookieService: CookieService,
        private readonly fireStore: AngularFirestore
    ) {
        this.getItems();
        this.getOrders();
     }

    private updateOrder(orders: Order[]): void {
        this.orderSource.next(orders);
    }

    private updateItems(items: ShopItems[]): void {
        this.itemSource.next(items);
    }

    public getOrders(): void {
        this.fireStore.collection<Order>('orders').valueChanges().subscribe(orders => {
            this.updateOrder(orders);
        });
    }

    public getItems(): void {
        this.fireStore.collection<ShopItems>('shop').valueChanges().subscribe(items => {
            this.updateItems(items);
        });
    }

    public async addOrder(order: Order): Promise<void> {
        this.fireStore.collection<Order>('orders').add(order).then(reference => {
            this.getOrders();
        });
    }
}
