import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geschenk1',
  templateUrl: './geschenk1.component.html',
  styleUrls: ['./geschenk1.component.scss']
})
export class Geschenk1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
