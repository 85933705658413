<div class="container shadow-sm fill mt-2">
  <h2>Alles Liebe zu deinem Geburtstag!</h2>
  <div class="d-lg-flex mt-2">
    <div class="px-1">
      Etwas ganz besonderes zu deinem <strong>22. Geburtstag</strong>!
    </div>
    <div class="px-1">
      Ich möchte dir einen <strong>ChairDance</strong> Workshop oder einen Teil deines Mitgliedsbeitrages für <strong>PoleFitness</strong> schenken.
    </div>
    <div class="px-1">
      Du kannst dir auch gerne das Studio aussuchen, aber ich würde dir das gerne schenken :)
    </div>
  </div>
  <hr>
  <div class="d-lg-flex flex-row justify-content-between align-items-center">
    <div class="w-100 h-auto">
        <img src="../../../assets/anna/pic.jpg" class="img-fluid">
    </div>
    <div class="d-flex justify-content-center m-5">
        <a href="https://uniquestudios.de" target="_blank">
            <img src="../../../assets/anna/Unique_logo.png">
        </a>
    </div>
  </div>
  <hr>
  <div>
    Du darfst dich auch gerne selber anmelden :)
  </div>
</div>