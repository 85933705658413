<div class="container shadow-sm fill">
    <div class="p-3">
        <ul class="nav nav-tabs d-flex justify-content-center">
            <li class="nav-item">
              <a class="nav-link" routerLink="invitation" routerLinkActive="active">Invitations</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="live" routerLinkActive="active" [ngClass]="{disabled : !liveEvent}">Live <span *ngIf="liveEvent" class="badge badge-primary">Event</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="shop" routerLinkActive="active">Shop</a>
            </li>
          </ul>
        <router-outlet></router-outlet>
    </div>
</div>

