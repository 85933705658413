<div class="container shadow-sm fill mt-2">
    <div class="d-lg-flex mt-2 justify-content-center">
        <img src="../../assets/anna/bob.gif" class="d-block">
    </div>
    <hr>
    <div id="slidesOnly" class="carousel slide" data-bs-ride="carousel" data-bs-interval=5000>
        <ol class="carousel-indicators">
            <li data-bs-target="#slidesOnly" data-bs-slide-to="0" class="active"></li>
            <li data-bs-target="#slidesOnly" data-bs-slide-to="1"></li>
          </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
              <img src="../../assets/anna/minions1.jpeg" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="../../assets/anna/minions2.jpeg" class="d-block w-100">
          </div>
        </div>
    </div>
    <hr>
    <div class="d-flex">
        <div>
            Der erste Film, der kommt, in den gehen wir!
        </div>
    </div>
    </div>