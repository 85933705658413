import { Component, OnInit } from '@angular/core';

export interface Cocktail {
    title: string;
    image: {
        src: string;
        alt: string
    };
    text: string;
    small?: string;
}

@Component({
    selector: 'app-cocktailabend',
    templateUrl: './cocktailabend.component.html',
    styleUrls: ['./cocktailabend.component.scss']
})
export class CocktailabendComponent implements OnInit {
    public content: Cocktail[] = [
        {
            title: 'Johannisbeer Mojito',
            image: {
                src: '../../../assets/cocktailabend/Johannnisbeer-Mojito-1.jpg',
                alt: 'Johannisbeer Mojito'
            },
            text: 'Mojito trifft Johannisbeere. Und genau deshalb gibt es einen Mojito mit etwas anderen Zutaten. Anders aber immer noch kubanisch lecker!'
        },
        {
            title: 'Hibiskus Heatwave',
            image: {
                src: '../../../assets/cocktailabend/Hibiskus-Cocktail-10.jpg',
                alt: 'Hibiskus Heatwave'
            },
            text: 'Sommerlich-ErfrischenderHibiskus Cocktail mit infused weißem Rum, frischen Himbeeren und Limette.'
        },
        {
            title: 'Pfirsich Bourbon',
            image: {
                src: '../../../assets/cocktailabend/Pfirsich-Bourbon-Cocktail-7.jpg',
                alt: 'Pfirsich Bourbon'
            },
            text: 'Pfirsiche zusammen mit rauchigem Bourbon und italienischer Zitronenlimonade.'
        },
        {
            title: 'Lockstoff',
            image: {
                src: '../../../assets/cocktailabend/boar-gin-lockstoff-rezept.jpg',
                alt: 'Lockstoff'
            },
            text: 'Mit Himbeeren und Minze. Die pure Erfrischung im Glas für besonders heiße Tage und Sommerabende.',
            small: 'Enthält Chinin'
        }
    ];
    public virginContent: Cocktail[] = [
        {
            title: 'Virgin Johannisbeer Mojito',
            image: {
                src: '../../../assets/cocktailabend/Johannnisbeer-Mojito-1.jpg',
                alt: 'Johannisbeer Mojito'
            },
            text: 'Mojito trifft Johannisbeere. Und genau deshalb gibt es einen virgin Mojito mit etwas anderen Zutaten. Statt Rum kommt hier Ginger Ale zum tragen. Anders aber immer noch kubanisch lecker!'
        },
        {
            title: 'Virgin Hibiskus Heatwave',
            image: {
                src: '../../../assets/cocktailabend/Hibiskus-Cocktail-10.jpg',
                alt: 'Hibiskus Heatwave'
            },
            text: 'Sommerlich-ErfrischenderHibiskus Cocktail mit mit Hibiskustee als Grundlage, frischen Himbeeren und Limette.'
        },
        {
            title: 'Pfirsich Melange',
            image: {
                src: '../../../assets/cocktailabend/Pfirsich-Bourbon-Cocktail-7.jpg',
                alt: 'Pfirsich Bourbon'
            },
            text: 'Pfirsiche ohne rauchigen Bourbon. Dafür mit mehr italienischer Zitronenlimonade.'
        },
        {
            title: 'Virgin Lockstoff',
            image: {
                src: '../../../assets/cocktailabend/boar-gin-lockstoff-rezept.jpg',
                alt: 'Lockstoff'
            },
            text: 'Mit Himbeeren und Minze. Die pure Erfrischung im Glas für besonders heiße Tage und Sommerabende. Mit alkoholfreiem Gin.',
            small: 'Enthält Chinin'
        }
    ];
    public showType = this.content;
    constructor() { }

    ngOnInit(): void {
    }

}
