import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gutschein',
  templateUrl: './gutschein.component.html',
  styleUrls: ['./gutschein.component.scss']
})
export class GutscheinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
