<div class="container shadow-sm fill mt-2">
<h2>Alles Liebe zu deinem Geburtstag!</h2>
<div class="d-lg-flex mt-2">
    <div class="px-1">
        Etwas ganz besonderes zu deinem <strong>22. Geburtstag</strong>!
    </div>
    <div class="px-1">
        Ich möchte dir eine erste Reise mit unserem <strong>Opel Corsa-e</strong> schenken.
    </div>
    <div class="px-1">
        Ich möchte dich in einen Wanderurlaub in die Schweiz entführen.<br>Mit schönen Touren zu Berg und Tal und ein paar kleineren Zugreisen zu unseren Wanderungen.
    </div>
</div>
<hr>
<div id="slidesOnly" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#slidesOnly" data-slide-to="0" class="active"></li>
        <li data-target="#slidesOnly" data-slide-to="1"></li>
        <li data-target="#slidesOnly" data-slide-to="2"></li>
        <li data-target="#slidesOnly" data-slide-to="3"></li>
        <li data-target="#slidesOnly" data-slide-to="4"></li>
        <li data-target="#slidesOnly" data-slide-to="5"></li>
      </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
          <img src="../../assets/anna/see.jpeg" class="d-block w-100">
          <div class="carousel-caption d-none d-md-block">
            <h5>Lago di Poschiavo</h5>
            <p>Mit Panorama Express</p>
          </div>
      </div>
      <div class="carousel-item">
        <img src="../../assets/anna/see2.JPG" class="d-block w-100">
        <div class="carousel-caption d-none d-md-block">
            <h5>Lago di Poschiavo</h5>
            <p>Wanderweg um den See herum</p>
          </div>
      </div>
      <div class="carousel-item">
        <img src="../../assets/anna/see3.jpeg" class="d-block w-100">
        <div class="carousel-caption d-none d-md-block">
            <h5>Lago da Saoseo</h5>
            <p>Im schönsten Wandertal der Alpen</p>
          </div>
      </div>
      <div class="carousel-item">
        <img src="../../assets/anna/berg.jpg" class="d-block w-100">
        <div class="carousel-caption d-none d-md-block">
            <h5>Wanderbeispiel</h5>
            <p>Denk dir bitte die Personen weg und genieß die Aussicht :)</p>
          </div>
      </div>
      <div class="carousel-item">
        <img src="../../assets/anna/tal.JPG" class="d-block w-100">
        <div class="carousel-caption d-none d-md-block">
            <h5>Val da Camp</h5>
            <p>Das schönste Wandertal der Alpen</p>
          </div>
      </div>
      <div class="carousel-item">
        <img src="../../assets/anna/gletscher.jpg" class="d-block w-100">
        <div class="carousel-caption d-none d-md-block">
            <h5>Gletscherhöhlen Cavaglia</h5>
            <p>Hoch oben in den Bergen gibt es auch etwas zu sehen</p>
          </div>
      </div>
    </div>
</div>
<hr>
<div class="d-flex">
    <div>
        Plandaten: September 2021 mit mindestens einer vollen Woche Urlaub
    </div>
</div>
</div>