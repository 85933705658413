
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnnaComponent } from './anna/anna.component';
import { Geschenk2Component } from './anna/geschenk2/geschenk2.component';
import { Geschenk1Component } from './anna/geschenk1/geschenk1.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './impressum/contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { InvitationComponent } from './watchparty/invitation/invitation.component';
import { LiveComponent } from './watchparty/live/live.component';
import { ShopComponent } from './watchparty/shop/shop.component';
import { WatchpartyComponent } from './watchparty/watchparty/watchparty.component';
import { HannahComponent } from './hannah/hannah.component';
import { GutscheinComponent } from './hannah/gutschein/gutschein.component';
import { PartiesComponent } from './parties/parties.component';
import { CocktailabendComponent } from './parties/cocktailabend/cocktailabend.component';
import { Geschenk3Component } from './anna/geschenk3/geschenk3.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent},
    { path: 'watchparty', component: WatchpartyComponent, children: [
        {
            path: '', redirectTo: 'invitation', pathMatch: 'full'
        },
        {
            path: 'invitation', component: InvitationComponent
        },
        {
            path: 'live', component: LiveComponent
        },
        {
            path: 'shop', component: ShopComponent
        }
    ]},
    { path: 'anna', component: AnnaComponent, children: [
        {
            path: '', redirectTo: 'minions', pathMatch: 'full'
        },
        {
            path: 'uniquestudios', component: Geschenk2Component
        },
        {
            path: 'poschiavo', component: Geschenk1Component
        },
        {
            path: 'minions', component: Geschenk3Component
        }
    ] },
    { path: 'hannah', component: HannahComponent, children: [
        {
            path: '', redirectTo: 'gutschein', pathMatch: 'full'
        },
        {
            path: 'gutschein', component: GutscheinComponent
        }
    ]},
    { path: 'parties', component: PartiesComponent, children: [
        {
            path: '', redirectTo: 'cocktailabend', pathMatch: 'full'
        },
        {
            path: 'cocktailabend', component: CocktailabendComponent
        }
    ]},
    { path: 'impressum', component: ImpressumComponent},
    { path: 'impressum/contact', component: ContactComponent},
    { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
