<div class="container shadow-sm fill mt-2">
  <h2>Cocktailabend</h2>
  <p>Cocktailabend mit Jana & Wolfgang</p>
  <div class="d-flex flex-row justify-content-center">
    <div class="btn-group" ngbRadioGroup name="radioBasic" [(ngModel)]="showType">
      <label ngbButtonLabel class="btn btn-outline-primary">
        <input ngbButton type="radio" class="btn-check" [value]=content> Alkohol
      </label>
      <label ngbButtonLabel class="btn btn-outline-primary">
        <input ngbButton type="radio" class="btn-check" [value]=virginContent> Alkoholfrei
      </label>
    </div>
  </div>
  <hr>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of showType;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-header bg-primary text-light">
              <h2>{{item.title}}</h2>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src={{item.image.src}} alt={{item.image.alt}}
                  class="img-fluid" style="width: 25vw;">
                <div>
                    <hr>
                  {{item.text}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
</div>
