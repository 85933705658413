<div class="modal-header bg-primary">
    <h5 class="modal-title text-light">Datenschutz & Cookies</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Diese Website sammelt keinerlei Daten gemäß DSGVO.<br><hr>
        Die Website nutzt essentielle Cookies, um eine Verbesserung der
        Website und die Nutzung der Website zu ermöglichen.
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="close()">Cookies Akzeptieren</button>
</div>