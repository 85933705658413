<ngb-alert *ngIf="successMessage" type="warning" class="fixed-top">
    <strong>{{alertType}}</strong> {{successMessage}}
</ngb-alert>

<div class="container shadow-sm">
    <div *ngIf="!liveEvent; else liveBlock">
        <div class="d-flex justify-content-start">
            <h2>Shop</h2>
        </div>
        <div>
            <ul class="nav nav-pills justify-content-center">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: view===0}" (click)="switchViews(0)">Auswahl</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{active: view===1, disabled: cartSize===0 || cartSize > 3}"
                        (click)="switchViews(1)">Kontaktinformationen</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"
                        [ngClass]="{active: view===2 || view===3, disabled: cartSize===0 || !contactValidated || cartSize > 3}"
                        (click)="switchViews(2)">Zusammenfassung</a>
                </li>
            </ul>
        </div>
        <div *ngIf="view===0">
            <form>
                <div class="d-flex flex-column m-2">
                    <div *ngFor="let item of shopItems" class="form-row shadow-sm my-1 py-3 bg-light rounded">
                        <label [for]="item.product" class="col-10">{{item.product}}</label>
                        <input class="col-2" type="number" [name]="item.product" [id]="item.product" min="0"
                            [max]="getMaxItem()" (change)="updateCart($event)" [value]="getQuantity(item)">
                    </div>
                    <hr>
                    <div class="form-row shadow-sm my-3 py-3 bg-light rounded">
                        <label for="sumUp" class="col-10">Gesamt:</label>
                        <div class="col-2">{{cartSize}}</div>
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary mb-3" [disabled]="cartSize===0 || cartSize > 3"
                    (click)="switchViews(1)">Weiter</button>
            </div>
        </div>
        <div *ngIf="view===1">
            <form class="d-flex flex-column m-2 flex-fill" (keyup.enter)="validateContact()">
                <div class="shadow-sm">
                    <div class="form-group my-1 p-3 w-auto">
                        <label for="Name">Name</label>
                        <input type="text" [(ngModel)]="shoppingCart.name" class="form-control" name="name" id="name"
                            placeholder="Name">
                    </div>
                    <div class="form-group my-1 p-3 w-auto">
                        <label for="mail">Email-Adresse</label>
                        <input type="email" [(ngModel)]="shoppingCart.email" class="form-control" name="mail" id="mail"
                            placeholder="Email-Adresse">
                    </div>
                </div>

            </form>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary mb-3" [disabled]="cartSize===0"
                    (click)="validateContact()">Weiter</button>
            </div>
        </div>
        <div *ngIf="view===2">
            <div class="container shadow-sm m-2">
                <table class="table table-borderless my-1 py-3">
                    <thead class="bg-primary text-white">
                        <tr>
                            <th>Produkt</th>
                            <th>Anzahl</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of shoppingCart.products">
                            <td>{{product.product}}</td>
                            <td>{{product.quantity}}</td>
                        </tr>
                    </tbody>
                    <hr>
                    <tfoot>
                        <tr>
                            <td>Gesamt</td>
                            <td>{{cartSize}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="my-1 m-2 py-3">
                <form>
                    Notiz
                    <div class="form-group">
                        <textarea name="area" id="area" [(ngModel)]="shoppingCart.notice" rows="5"
                            columns="10"></textarea>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary mb-3" (click)="placeOrder()">Checkout</button>
            </div>
        </div>
        <div *ngIf="view===3">
            <div class="container shadow-sm m-2 mb-3 py-3">
                <h5>Vielen Dank für deine Bestellung!</h5>
                <p>Du kannst dir deine Pizza zu diesem Zeitpunkt abholen:</p>
                <hr>
                <h6>{{this.shoppingCart.time * 1000 | date:'dd.MM.yyyy - HH:mm'}} Uhr</h6>
                <hr>
                <p><strong>Abholung:</strong> Wolframstraße 5, 97070 Würzburg</p>
            </div>
        </div>
    </div>
    <ng-template #liveBlock>
        <div class="d-flex justify-content-start">
            <h2>Shop</h2>
        </div>
        <div class="d-flex justify-content-center">
            <div class="m-5 p-3 d-flex flex-column justify-content-center">
                <strong class="text-center">Keine Bestellungen mehr möglich!</strong>
            </div>
        </div>
    </ng-template>
</div>