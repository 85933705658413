import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anna',
  templateUrl: './anna.component.html',
  styleUrls: ['./anna.component.scss']
})
export class AnnaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
